import './App.css';

function App() {
    return (
        <div style={{padding: '50px'}}>
                <h1>Dykare.nu</h1>
            <p style={{padding: '50px'}}>
                Dykare.nu bedriver dyk relaterad verksamhet i Sundsvall/Härnösand och Göteborg.<br/>

                Vanligtvis så gör vi ”Prova-på-dyk” i pool löpande, hör av dig om du eller någon du känner vill
                hoppa i spat tillsammans med oss.<br/>

                Om du känner att det behövs övning, abstinensen är för stor eller att det är för kallt ute så
                kontakta oss för att få komma i ”varmare” vatten.<br/>

                Vi finns även på Facebook
                http://www.facebook.com/groups/dykare.nu/<br/>
            </p>
            <p>
                <h3>Kontakt</h3>
                Såhär når du oss<br/>
                <h4>
                Fredrik Gustafsson (ingen verksamhet i Göteborg så ring inte hit om kurser/jobb)</h4>
                Epost: fredrik@fredrikgustafsson.se<br/>
                Mobil: 070 69 61 623<br/>
                <br/>
                <h4>
                Fredrik Nilsson (ring hit för info om kurser/jobb i Sundsvall/Härnösand )</h4>
                Epost: fredrik.nilsson@dykare.nu<br/>
                Mobil: 070 64 20 670<br/>
                <br/>
            </p>
        </div>
    );
}

export default App;
